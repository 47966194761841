MuiStepConnector-root {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    position: absolute;
    top: 13px;
    left: calc(-50% + 14px);
    right: calc(50% + 14px);
}

MuiStepConnector-line {
    display: block;
    border-color: #df1d1d;
    border-top-style: solid;
    border-top-width: 3px;
}