.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}

.slider-wrapper {
    width: 100%;
    max-width: 600px; /* Максимальная ширина для карточек */
    margin: 10px 0; /* Отступы между карточками */
    border-radius: 15px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #f5f7fe;
}

h3 {
    text-align: center; /* Центрируем заголовок */
    margin-bottom: 10px; /* Отступ под заголовком */
}

/* Стили для точек */
.slick-dots {
    position: relative; /* Устанавливаем относительно родителя */
    bottom: 35px; /* Поднимаем точки на уровень картинки */
    display: flex !important; /* Выравниваем точки по центру */
    justify-content: center; /* Центрируем точки */
}

.slick-dots li {
    margin: 0 5px; /* Отступы между точками */
}

.slick-dots li button {
        display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ffffff;
    border: 2px solid #ec6608;
}

.slick-dots li.slick-active button {
   background: #ec6608; /* Цвет активной точки */
}
.slick-dots li.slick-active button:before {
    opacity: 0;
    color: black;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    content: '•';
    text-align: center;
    opacity: 0;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-list {
    border-radius: 15px;
    
}
/* Добавим анимацию для переливания */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Класс для переливания изображения */
.pulse {
  animation: pulse 2s infinite; /* Анимация будет происходить каждые 1.5 секунды */
}