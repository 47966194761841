.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.slider-wrapper {
    width: 100%;
    margin: 10px 0; /* Отступы между карточками */
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 6px 1px -10px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
}