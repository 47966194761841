@font-face {
  font-family: 'Circe Bold';
  src: url('./assets/fonts/circe_bold.eot'); /* IE 9 Compatibility Mode */
  src: url('./assets/fonts/circe_bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('./assets/fonts/circe_bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/circe_bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('./assets/fonts/circe_bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/circe_bold.svg#circe_bold') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'Circe ExtraBold';
  src: url('./assets/fonts/circe_extrabold.eot'); /* IE 9 Compatibility Mode */
  src: url('./assets/fonts/circe_extrabold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('./assets/fonts/circe_extrabold.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/circe_extrabold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('./assets/fonts/circe_extrabold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/circe_extrabold.svg#circe_extrabold') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'Circe ExtraLight';
  src: url('./assets/fonts/circe_extralight.eot'); /* IE 9 Compatibility Mode */
  src: url('./assets/fonts/circe_extralight.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('./assets/fonts/circe_extralight.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/circe_extralight.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('./assets/fonts/circe_extralight.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/circe_extralight.svg#circe_extralight') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'Circe Light';
  src: url('./assets/fonts/circe_light.eot'); /* IE 9 Compatibility Mode */
  src: url('./assets/fonts/circe_light.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('./assets/fonts/circe_light.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/circe_light.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('./assets/fonts/circe_light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/circe_light.svg#circe_light') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'Circe';
  src: url('./assets/fonts/circe.eot'); /* IE 9 Compatibility Mode */
  src: url('./assets/fonts/circe.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('./assets/fonts/circe.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/circe.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('./assets/fonts/circe.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/circe.svg#circe') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'Circe Thin';
  src: url('./assets/fonts/circe_thin.eot'); /* IE 9 Compatibility Mode */
  src: url('./assets/fonts/circe_thin.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('./assets/fonts/circe_thin.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/circe_thin.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('./assets/fonts/circe_thin.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/circe_thin.svg#circe_thin') format('svg'); /* Chrome < 4, Legacy iOS */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  background-color: #fff; /* Цвет фона, если необходим */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-screen img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Подходит для изображений */
}
body {
  background-color :#fff;
  font-family: 'Circe', sans-serif !important;
}
.MuiTypography-root{
  font-family: 'Circe', sans-serif !important;
}

.sliderImg{
  object-fit: cover;
    width: 100%;
}